<template>
  <div>
    <v-container>
      <v-card outlined class="mb-6">
        <v-card-title class="d-flex justify-start align-center grey lighten-3">
          <div class="flex-grow-1"></div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.clpForm.openForm(litter)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>

        <v-simple-table class="mb-5 table" divider="true">
          <tbody>
            <tr>
              <th>Add Puppies to CLP</th>
              <td>{{ litter.clp_visible ? "Yes" : "No" }}</td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-row>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Intro - Photo Uploads</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    @click="$refs.introImagesForm.openForm('intro')"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Image</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-row>
              <v-col
                cols="4"
                v-for="image in litter.intro_images"
                v-bind:key="image.id"
              >
                <v-card>
                  <v-img
                    :src="image.asset_urls.url"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    width="100"
                  >
                  </v-img>
                  <v-card-actions>
                    <v-btn icon color="red" @click="openDelete(image, 'intro')">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      color="blue"
                      @click="$refs.introImagesForm.openForm('intro', image)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">3 Weeks -Photo Uploads</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    @click="$refs.introImagesForm.openForm('three')"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-row>
              <v-col
                cols="4"
                v-for="image in litter.three_weeks_images"
                v-bind:key="image.id"
              >
                <v-card>
                  <v-img
                    :src="image.asset_urls.url"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    width="100"
                  >
                  </v-img>
                  <v-card-actions>
                    <v-btn icon color="red" @click="openDelete(image, 'three')">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      color="blue"
                      @click="$refs.introImagesForm.openForm('three', image)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">6 Weeks - Photo Uploads</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    @click="$refs.introImagesForm.openForm('six')"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-row>
              <v-col
                cols="4"
                v-for="image in litter.six_weeks_images"
                v-bind:key="image.id"
              >
                <v-card>
                  <v-img
                    :src="image.asset_urls.url"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    width="100"
                  >
                  </v-img>
                  <v-card-actions>
                    <v-btn icon color="red" @click="openDelete(image, 'six')">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      color="blue"
                      @click="$refs.introImagesForm.openForm('six', image)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        scrollable
        v-model="deleteDialog.open"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">Delete Image</v-card-title>
          <v-card-text>Are you sure you want to delete this image?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteDialog.loading"
              @click="saveDelete"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <IntroImagesForm ref="introImagesForm" />
      <ClpForm ref="clpForm" />
    </v-container>
  </div>
</template>

<script>
import IntroImagesForm from "../components/clp/IntroImagesForm";
import ClpForm from "../components/clp/clpForm";

export default {
  components: { IntroImagesForm, ClpForm },

  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
        type: null,
      },
    };
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },

  methods: {
    openDelete(image, type) {
      this.deleteDialog.image = image;
      this.deleteDialog.type = type;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("lhl/litters/deleteImage", {
          appId,
          litterId: this.$route.params.litterId,
          imageId: this.deleteDialog.image.id,
          type: this.deleteDialog.type,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
